<template>
  <nuxt-layout name="services">
    <transition name="fade">
      <j-loader-indicator
        v-if="getShowPageLoader"
        fixed
      />
    </transition>
    <component :is="Component as string" />
  </nuxt-layout>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useLayoutStore } from '~/stores/layout';
import {
  INFO_MESSAGE_PAGE_PARAMS,
  INFO_MESSAGE_PAGE_ROUTE,
} from '~/constants/infoMessages';
import { UNAUTHORIZED_STATUS_CODES } from '~/constants/apiConfiguration';

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
});
const router = useRouter();
const isShowPageNotFound = computed( () => {
  return props?.error?.statusCode === 404;
});
const isAuthenticationError = computed( () => {
  return UNAUTHORIZED_STATUS_CODES.includes(props?.error?.statusCode);
});

const layoutStore = useLayoutStore();
const { setPageLoader } = layoutStore;
const { getShowPageLoader } = storeToRefs(layoutStore);
const Component = computed(() => {
  switch(props?.error?.statusCode) {
    case 404:
      return resolveComponent('JPageError404');
    default:
      return resolveComponent('JPageErrorDefault');
  }
});

//Chat initialization and connection
const authStore = useAuthStore();
const {
  initChat,
  logout,
} = authStore;

onMounted(async () => {
  if (isAuthenticationError.value) {
    await logout();
    await router.push(
      { path: useLocalePrefixForHref(`${INFO_MESSAGE_PAGE_ROUTE}/${INFO_MESSAGE_PAGE_PARAMS.sessionExpired}`) },
    );
  } else {
    initChat();
    setPageLoader(false);
    if (!isShowPageNotFound.value) {
      throw (toRaw(props?.error));
    }
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <v-snackbar
    contained
    :content-class="[
      'j-notification',
      'pa-6',
      'rounded-lg',
      { 'j-notification--horizontal': isHorizontal },
      { 'j-notification--hide': isHideNotification },
    ]"
    :class="['j-notification-overlay', { 'j-notification-overlay--horizontal ma-4': isHorizontal }]"
    :model-value="componentProps.modelValue!"
    :vertical="componentProps.vertical!"
    :timeout="timeout"
    :location="locationComputed"
    :width="width"
    :min-width="width"
    :max-width="componentProps.maxWidth!"
    :color="color"
    z-index="99999"
    @update:model-value="close"
  >
    <v-btn
      v-if="!isHorizontal"
      class="pa-0 j-btn j-btn--no-hover j-btn--no-active j-btn--absolute j-btn--close-modal"
      :min-width="32"
      :ripple="false"
      variant="text"
      @click="close"
    >
      <v-img
        :width="32"
        :src="images['notification-close']"
        alt="icon notification-close"
      />
    </v-btn>
    <general-text
      v-if="componentProps.title"
      class="j-text--fs-24 j-text--white mb-4"
      :text="componentProps.title"
    />
    <general-text
      v-if="componentProps.text"
      class="j-text--white text-center"
      :text="componentProps.text"
    />
    <template
      v-if="componentProps.buttons!.length"
      #actions
    >
      <v-btn
        v-for="(button, buttonIndex) of componentProps.buttons"
        :key="buttonIndex"
        :class="[
          'text-none',
          'text-body-1',
          'px-6',
          'px-lg-5',
          'j-btn',
          button.class,
        ]"
        :color="button.color!"
        variant="flat"
        size="large"
        @click="handler(button.action)"
      >
        {{ $t(button.dictionaryKey) }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCashStore } from '~/stores/cash';
import type {
  NotificationsEmit,
  NotificationsItemButtons,
  NotificationButtonAction,
} from '~/types/components/General/Notifications';

const componentProps = defineProps({
  id: {
    type: Number,
    default: null,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  vertical: {
    type: Boolean,
    default: true,
  },
  isCookie: {
    type: Boolean,
    default: false,
  },
  isFreeSpins: {
    type: Boolean,
    default: false,
  },
  isEmailConformation: {
    type: Boolean,
    default: false,
  },
  timeout: {
    type: Number,
    default: 0,
  },
  width: {
    type: [String, Number],
    default: '',
  },
  maxWidth: {
    type: [String, Number],
    default: 'initial',
  },
  buttons: {
    type: Array as () => NotificationsItemButtons[],
    default: () => [],
  },
  color: {
    type: String,
    default: '',
  },
});
const emit = defineEmits([
  'remove',
  'close',
  'readCookie',
  'gameLink',
]);

const cashStore = useCashStore();
const { getShowCashModal } = storeToRefs(cashStore);
const route = useRoute();
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const images = useAssetsImages();
const scssVariables = useScssVariables();
const width = computed(() => {
  if(isHorizontal.value && !componentProps.width) {
    return isMobileMode?.value
    ? 'initial'
    : '100%';
  }

  return componentProps.width || '296px';
});
const isGamePageOrCashierModal = computed(() =>
  route.path.includes('/game/') || getShowCashModal.value);
const isHideNotification = computed(() =>
  componentProps.isEmailConformation && getShowCashModal.value
    ? false
    : isGamePageOrCashierModal.value && !componentProps.isCookie);
const timeout = computed(() => componentProps.timeout || 30000);
const color = computed(() => componentProps.color || scssVariables.jColorBgBlockLight);
const isHorizontal = computed(() => componentProps.vertical === false);
const locationComputed = computed(() => isHorizontal.value
  ? 'bottom right'
  : 'top right',
);
const close = () => {
  componentProps.isFreeSpins
    ? emit('close')
    : emit('remove');
};
const handler = (action: NotificationsEmit | NotificationButtonAction ) => {
  if (typeof action === 'string') {
    emit(`${action}`);
  } else {
    emit(`${action.name}`, action);
  }

  componentProps.isFreeSpins
    ? emit('close')
    : emit('remove');
};
</script>
<style lang="scss" scoped>
.j-notification-overlay {
  position: initial;
  margin: 0 26px 22px 0;
  :deep(.v-snackbar__content) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  :deep(.v-snackbar__actions) {
    margin: 16px 0 0 !important;
    align-self: initial !important;
  }
  :deep(.j-notification) {
    overflow: initial;
    position: initial;
  }
  :deep(.j-notification--hide) {
    display: none;
  }
  :deep(.j-notification--horizontal) {
    flex-direction: column;
    @media (min-width: 1280px) {
      justify-content: center;
      flex-direction: initial;
      .v-snackbar__content {
        flex-grow: initial;
        margin-right: 0;
      }
      .v-snackbar__actions {
        margin: 0 0 0 36px !important;
      }
    }
  }
}
.j-notification-overlay--horizontal {
  position: fixed;
  z-index: 999999 !important;
}
</style>

import { COOKIE } from '~/constants/storedPropertiesNames';
/**
 * Nuxt plugin to ensure the active language cookie is set.
 *
 * This plugin checks if the 'activeLanguage' cookie is set. If the cookie is not set
 * it initializes the cookie with a specific default language 'ru'. This ensures that
 * the application uses 'ru' as the default language instead of relying on the system
 * default language settings.
 *
 */
export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp();
  const activeLanguageCookie = useCookie(COOKIE.activeLanguage);

  if (!activeLanguageCookie.value) {
    activeLanguageCookie.value = $i18n.defaultLocale;
  }
});

<template>
  <h2
    ref="teleportedContent"
    class="j-text j-text--fs-24 j-text--white text-center font-weight-regular mb-6"
  >
    {{ $t("cashier_choose_replenishment_amount") }}
  </h2>
</template>

<script setup lang="ts">
const teleportedContent = ref(null);

onMounted(() => {
  if (import.meta.client) {
    const target = document.querySelector('#tab-amount');

    if (target && teleportedContent.value) {
      target.insertBefore(teleportedContent.value, target.firstChild);
    }
  }
});
</script>

<template>
  <div class="j-page-error-404 d-flex flex-column mt-6 mt-lg-16 pt-lg-6">
    <div class="overflow-auto">
      <v-img
        :lazy-src="useImgSrc('/images/servicePages/404_desktop.png', 'webp')"
        :src="useImgSrc('/images/servicePages/404_desktop.png', 'webp')"
        alt="404"
        class="ma-auto"
      />
    </div>
    <div class="text-center mt-12">
      <general-text
        :text-types="TEXT_TYPES.pageTitle"
        dictionary-key="404_error_page_title"
        class="j-text--white"
      />
    </div>
    <div class="text-center mt-2">
      <general-text
        :text-types="TEXT_TYPES.subTitle"
        dictionary-key="404_error_try_again"
        class="j-text--white"
      />
    </div>
    <div class="text-center mt-10 mb-16">
      <v-btn
        :color="scssVariables.jColorBtnRegular"
        :to="useLocalePrefixForHref('/')"
        variant="flat"
        size="large"
        class="text-none text-body-1"
        @click="changeUrl"
      >
        <general-text
          :text-types="TEXT_TYPES.subTitle"
          dictionary-key="404_error_go_back"
          class="j-text--white"
        />
      </v-btn>
    </div>
  </div>
</template>
<script setup lang="ts">
import { TEXT_TYPES } from '~/constants/general';

const scssVariables = useScssVariables();
const changeUrl = () => {
  window.history.pushState({}, '', useLocalePrefixForHref('/'));
};
</script>
<style lang="scss" scoped>
.j-page-error-404 {
  height: calc(100vh - 154px);
  max-height: 100%;
}
</style>
<template>
  <div class="d-flex flex-column j-cash-promo-card-small-wrapper w-100">
    <div
      :class="[
        'j-cash-promo-card-small rounded-lg',
        {
          'j-cash-promo-card-small--active': isActivated,
          'j-cash-promo-card-small--disabled': isDisabled,
        },
      ]"
    >
      <v-sheet
        :color="
          isActivated ? scssVariables.jColorBgBlockDark : scssVariables.jColorBtnRegular
        "
        class="pa-2 text-center"
        rounded="lg"
      >
        <v-img
          v-if="loading"
          class="j-cash-promo-card-small__loader mx-auto"
          :lazy-src="images['page_loader']"
          :src="images['page_loader']"
          :width="48"
          alt="Preloader..."
        />
        <div
          v-else
          class="d-flex"
        >
          <v-img
            :src="iconSrc"
            :width="20"
            :height="20"
            alt="image promo"
            inline
            class="text-start mr-3"
          />
          <v-img
            :src="imgSrc"
            :max-width="48"
            :height="48"
            alt="icon promo"
            class="mr-4 j-cash-promo-card-small__img"
          />
          <div class="mr-3">
            <general-text
              v-if="promoCardData.deposit_from"
              dictionary-key="general_conditions_receiving"
            />
            <general-text
              :text="depositFromText"
              :class="[
                'j-text',
                'j-text--fs-16',
                { 'j-text--white': !isDisabled },
                'font-weight-medium',
                promoCardData.deposit_from ? 'mt-1' : 'mt-5',
              ]"
            />
          </div>
          <general-tooltip
            v-if="promoCardData.deposit_from"
            location="bottom right"
            content-class="j-tooltip--cash-promo"
          >
            <template #activator="{ isActive, props }">
              <v-icon
                v-bind="props"
                :class="['ml-auto', { 'j-tooltip-icon': !isActive }]"
                :icon="'custom:tooltipIconActive'"
              />
            </template>
            <div class="j-tooltip__content j-tooltip__content--card-small text-start">
              <div class="px-2">
                <template
                  v-for="(tooltipItem, index) of tooltipContent"
                  :key="index"
                >
                  <general-text
                    :dictionary-key="tooltipItem.dictionaryKey"
                    :class="[
                      'j-text',
                      'j-text--fs-12',
                      index === 0 ? 'mt-1' : 'mt-4',
                    ]"
                  />
                  <general-text
                    :text="String(tooltipItem.text)"
                    class="j-text j-text--fs-12 j-text--white"
                  />
                </template>
              </div>
              <v-sheet
                :color="scssVariables.jColorBgBlock"
                class="px-2 py-4 mt-4"
                rounded
              >
                <nuxt-link
                  v-for="(link, index) of gamesLinks"
                  :key="index"
                  :to="link.url"
                  class="j-link j-link--main-gradient"
                >
                  {{ link.name }}
                </nuxt-link>
                <general-sanitized-html-container :html-string="promoCardData.description" />
              </v-sheet>
            </div>
          </general-tooltip>
        </div>
      </v-sheet>
    </div>
    <div v-if="!hideVerificationControl">
      <v-sheet
        v-if="promoCardData.phone"
        :color="scssVariables.jColorBtnRegular"
        class="d-flex flex-wrap justify-space-between align-center px-2 py-1 mt-1"
        @click.stop
      >
        <nuxt-link
          class="j-link j-link--main-gradient"
          @click.stop="router.push(useLocalePrefixForHref('/cabinet/personal-data'))"
        >
          <general-text dictionary-key="general_verify_phone" />
        </nuxt-link>
        <v-icon :color="scssVariables.jColorBtnGradientMainFallBack">
          mdi-clock-outline
        </v-icon>
      </v-sheet>
      <v-sheet
        v-if="promoCardData.mail"
        :color="scssVariables.jColorBtnRegular"
        class="d-flex flex-wrap justify-space-between align-center px-2 py-1 mt-1"
        @click.stop
      >
        <nuxt-link
          class="j-link j-link--main-gradient"
          @click.stop="useConfirmationEmail"
        >
          <general-text dictionary-key="general_confirm_email" />
        </nuxt-link>
        <v-icon :color="scssVariables.jColorBtnGradientMainFallBack">
          mdi-clock-outline
        </v-icon>
      </v-sheet>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PromotionItem } from '~/types/pages/cabinet';
import {
  PROMOTION_DATA,
  REQUIRED_AUTH_PAGES_WITH_CHILDREN,
} from '~/constants/general';

const componentProps = defineProps({
  promoCardData: {
    type: Object as PropType<PromotionItem>,
    default: () => ({}),
  },
  isActivated: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  hideVerificationControl: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const images = useAssetsImages();
const scssVariables = useScssVariables();
const router = useRouter();
const imgSrc = computed(() =>
  !componentProps.promoCardData.type_bonus
    ? images['skip_bonuses']
    : componentProps.promoCardData.type_bonus === PROMOTION_DATA.regularBonus
      ? images['bonus']
      : images['free_spins'],
);
const iconSrc = computed(() =>
  componentProps.isActivated
    ? images['radio-true']
    : images['radio-false'],
);
const depositFromText = computed(() =>
  componentProps.promoCardData.deposit_from
    ? `${t('general_deposit_from')} ${
        componentProps.promoCardData.deposit_from
      } ${componentProps.promoCardData.currency}`
    : t('general_skip_bonuses'),
);
const tooltipContent = computed(() => [
  {
    dictionaryKey: 'general_valid_until',
    text: useFormatDate(componentProps.promoCardData.date_end),
  },
  {
    dictionaryKey: 'general_conditions_receiving',
    text: depositFromText.value,
  },
  {
    dictionaryKey: 'general_wagering_coefficient',
    text: componentProps.promoCardData.wager,
  },
  {
    dictionaryKey: 'general_wagering_period',
    text: `${componentProps.promoCardData?.lifetime} ${t('general_days')}`,
  },
]);
const gamesLinks = computed(() =>
  componentProps.promoCardData.games.map((game) => ({
    name: game?.name,
    url: `/game/${game?.prettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`,
  })),
);
const isDisabled = computed(() => componentProps.promoCardData.mail || componentProps.promoCardData.phone);
</script>

<style lang="scss" scoped>
.j-cash-promo-card-small-wrapper {
  max-width: 292px;
}
.j-cash-promo-card-small {
  cursor: pointer;
  padding: 2px;
  min-height: 66px;
}
.j-cash-promo-card-small__loader {
  @include spinning(2s);
}
.j-cash-promo-card-small--active {
  background: $j-gradient-btn-main;
}
.j-cash-promo-card-small--disabled {
  cursor: auto;
  .j-cash-promo-card-small__img {
    filter: grayscale(1);
  }
}
</style>

<template>
  <v-card
    v-if="showCashContainer"
    ref="cashContainer"
    id="cash"
    :class="[
      'j-cash',
      'px-4',
      'mx-0',
      'px-lg-5',
      'mx-lg-11',
      'd-flex',
      'flex-column',
      {
        'mt-2 mt-lg-16 pt-5': selectPaySystemScreenOpened && (!activePromo || isMobile) && !isWithdrawal,
        'j-cash--active-promo': showPromotionsItem && activePromo,
        'j-cash--out': isWithdrawal,
      },
    ]"
    :elevation="0"
    :color="scssVariables.jColorBgBlockDark"
  >
    <j-cash-amount-tab-title v-if="showCashAmountTabTitle" />
    <j-cash-promotions
      v-if="showPromotions"
      @promo-toggle="activatedNextBtn"
    />
    <j-cash-promotions-item
      v-if="showPromotionsItem && activePromo"
      :teleport-to="teleportTo"
      :promo-card-data="activePromo"
    />
  </v-card>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import { useCabinetStore } from '~/stores/cabinet';
import { useCashStore } from '~/stores/cash';
import { BLOCKED_COUNTRIES } from '~/constants/cash';
import {
  PLATFORM_BY_DEVICE_TYPE,
  PROMOTION_DATA,
  APP_RUN_MODES,
} from '~/constants/general';
import type {
  CashConfigObject,
  CashContainerEvent,
} from '~/types/general/cash';

const { fetchCashConfig } = useGlobalsStore();
const { getCashConfig } = storeToRefs(useGlobalsStore());
const { getUserData } = storeToRefs(useAuthStore());
const { getCashType } = storeToRefs(useCashStore());
const scssVariables = useScssVariables();
const staticStorageUrl = useBaseAppUrl().static;
const cabinetStore = useCabinetStore();
const { fetchPromotionsData } = cabinetStore;
const { getPromotions } = storeToRefs(cabinetStore);
const activePromo = computed(() => getPromotions.value.find((promo) => promo.state === PROMOTION_DATA.active));
const { isMobile } = useDevice();
const activatedTabName = ref<string | null>(null);
const baseTeleportTo = ref<string | null>(null);
const teleportTo = computed(() => {
  // Set the teleport target based on the activated tab and device size
  if (baseTeleportTo.value) {
    if (isMobile) {
      switch(activatedTabName.value) {
        case 'amount':
          baseTeleportTo.value = '#bonus-block';
        break;
        case 'submit':
          baseTeleportTo.value = '#bonus-submit-block';
        break;
        case 'crypto-submit':
          baseTeleportTo.value = '#tab-crypto-submit';
      }
    } else {
      baseTeleportTo.value = '.c-block--payment-header .c-block';
    }

    return baseTeleportTo.value;
  }

  return isMobile
    ? '.v-application__wrap'
    : '#cash';
});
const showPromotions = ref(false);
const showPromotionsItem = ref(false);
const selectPaySystemScreenOpened = ref(false);
const showCashAmountTabTitle = ref(false);
const cashContainer = ref<ComponentPublicInstance | null>(null);
const isWithdrawal = computed(() => getCashType.value === 'out');
const loadCashScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = `${staticStorageUrl}/payment/cash.js`;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

/**
 * Modifies API endpoint paths in the provided configuration object based on the application run mode.
 * In development mode it prepends a specific host URL to paths that begin with '/back/payment'.
 * For nested objects, the function is applied recursively.
 * This function is used to dynamically adjust API paths without modifying the configuration object structure.
 *
 * @param {CashConfigObject} config - The configuration object that contains API paths.
 * @returns {CashConfigObject} The updated configuration object with modified API paths if development mode runs
 */
const addApiContextToPath = (config: CashConfigObject): CashConfigObject => {
  const updatedConfig = { ...config };

  if (process.env.NODE_ENV === APP_RUN_MODES.development) {
    Object.keys(updatedConfig).forEach((key) => {
      const value = updatedConfig[key];

      if (typeof value === 'string') {
        if (value.startsWith('/back/payment')) {
          // TODO: Resolve auth issue and replace with https://jozz.dev/
          updatedConfig[key] = `https://jozz.dev${value}`;
        }
      } else if (typeof value === 'object' && value !== null) {
        updatedConfig[key] = addApiContextToPath(value as CashConfigObject);
      }
    });
  }

  return updatedConfig;
};
const showCashContainer = ref(false);
const initializeCash = async () => {
  showCashContainer.value = false;
  activatedTabName.value = null;
  baseTeleportTo.value = null;
  showPromotionsItem.value = false;
  showPromotions.value = false;

  if (
    getUserData.value.test ||
    (getCashType.value &&
    !BLOCKED_COUNTRIES[getCashType.value].includes(getUserData.value.currentCountry || '') &&
    !BLOCKED_COUNTRIES[getCashType.value].includes(getUserData.value.registrationCountry || ''))
  ) {
    try {
      showCashContainer.value = true;
      getCashType.value && await fetchCashConfig(getCashType.value);
      if (cashContainer.value && window.cashInitializer) {
        // TODO: Update this code after API context will be set on BE side
        const cashConfigWithAPIContext = addApiContextToPath(getCashConfig.value);

        window.cashInitializer(cashContainer.value.$el, cashConfigWithAPIContext);

        // Add event listener for when a tab is activated (after selecting (activating) tab)
        cashContainer.value.$el.addEventListener(
          'tabs.activated',
          (event: CashContainerEvent) => {
            // Set the tab name
            activatedTabName.value = event.detail.tab.name;

            nextTick(() => {
              // Show promotions item based on the activated tab with 'bonuses' name
              showPromotionsItem.value = activatedTabName.value !== 'bonuses';
            });
            showCashAmountTabTitle.value = activatedTabName.value.includes('amount');
        });

        // Add event listener for when gateways are opened (after rendering all gateways (main Cash page))
        cashContainer.value.$el.addEventListener('gateways.opened', () => {
          nextTick(() => {
            showPromotionsItem.value = !isWithdrawal.value;
            selectPaySystemScreenOpened.value = true;
          });
        });

        // Add event listener for when a gateway is chosen (after selecting some gateway)
        cashContainer.value.$el.addEventListener(
          'gateway.choose', (event: CashContainerEvent) => {
            selectPaySystemScreenOpened.value = !Boolean(event.detail.gateway);
            nextTick(() => {
              // Set the teleport target
              baseTeleportTo.value = !event.detail.gateway
                ? null
                : '.c-block--payment-header .c-block';
              // Set the tab name
              activatedTabName.value = event.detail.gateway
                ? activatedTabName.value
                : null;

              // Show promotions based on gateway choice
              showPromotions.value = Boolean(event.detail.gateway) && !isWithdrawal.value;
              showPromotionsItem.value = !isWithdrawal.value;

              // Disabling nextBtn if promo doesn't chosen
              if (showPromotions.value && !activePromo.value) {
                const nextBtn = event.target.querySelector('.c-btn--next');

                nextBtn?.classList.add('c-btn--disabled');
                nextBtn?.setAttribute('disabled', '');
              }
            });
        });
      }
    } catch (error) {
      showCashContainer.value = false;
      throw error;
    }
  }
};
const activatedNextBtn = () => {
  const nextBtn = cashContainer.value?.$el.querySelector('.c-btn--next');

  nextBtn?.classList.remove('c-btn--disabled');
  nextBtn?.removeAttribute('disabled');
};

defineExpose({ initializeCash });

onMounted(() => {
  nextTick(async () => {
    await loadCashScript();
    await initializeCash();
    await fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
  });
});

onUnmounted(() => {
  cashContainer.value?.$el.removeEventListener('gateways.opened');
  cashContainer.value?.$el.removeEventListener('tabs.activated');
  cashContainer.value?.$el.removeEventListener('gateway.choose');
});
</script>

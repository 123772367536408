<template>
  <div class="j-cash-mobile-menu-header d-flex align-center justify-space-between px-4 py-2 mb-6">
    <general-text
      :dictionary-key="dictionaryKeyTitle"
      class="j-text--fs-16 j-text--white font-weight-medium mx-auto"
    />
    <v-btn
      :class="[
        'pa-0',
        'ml-n8',
        'j-btn',
        'j-btn--no-hover',
        'j-btn--no-active',
      ]"
      :min-width="32"
      :ripple="false"
      variant="text"
      @click="closeCashModal"
    >
      <v-img
        :width="32"
        :src="images['notification-close']"
        alt="icon notification-close"
      />
    </v-btn>
  </div>
  <v-stepper
    v-model="step"
    :items="items"
    hide-actions
    flat
    bg-color="transparent"
    class="j-cash-mobile-stepper"
  >
    <template #[`item.1`]>
      <v-list
        density="compact"
        bg-color="transparent"
        class="j-cash-mobile-menu-list py-0 px-4"
      >
        <v-list-item
          v-for="(item, index) of formattedMobileSteps"
          :key="index"
          rounded="lg"
          :ripple="false"
          :prepend-icon="item.icon"
          class="mb-4 px-4 py-5 j-cash-mobile-menu-list__item"
          @click="nextStep(index)"
        >
          <v-list-item-title>
            <general-text
              :dictionary-key="`cashier_${item.value}`"
              class="j-text--fs-16 j-text--white font-weight-medium"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <template #[`item.2`]>
      <j-cash />
    </template>
  </v-stepper>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCashStore } from '~/stores/cash';
import { CASH_TYPES } from '~/constants/cash';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import type { CashTypes } from '~/types/stores/cash';

const items = ['', ''];
const step = ref<number>(1);
const route = useRoute();
const cashStore = useCashStore();
const {
  setCashType,
  closeCashModal,
} = cashStore;
const images = useAssetsImages();
const formattedMobileSteps = computed(() => {
  return CASH_TYPES.map((type) => {
    return {
      value: type,
      icon: `custom:cashier${type.charAt(0).toUpperCase() + type.slice(1)}Icon`,
    };
  });
});
const { getCashType } = storeToRefs(cashStore);
const dictionaryKeyTitle = computed(() => getCashType.value
  ? `cashier_${getCashType.value}`
  : 'general_cashier');
const nextStep = (index: number) => {
  step.value += 1;
  setCashType(CASH_TYPES[index] as CashTypes);
};

onBeforeMount(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const cashierQuery = route.query[REDIRECTION_ROUTES_QUERIES.cashier]
    || queryParams.get(REDIRECTION_ROUTES_QUERIES.cashier);

  if (CASH_TYPES.includes(cashierQuery as string)) {
    nextStep(CASH_TYPES.indexOf(cashierQuery as string));
  }
});
</script>
<style lang="scss" scoped>
.j-cash-mobile-stepper {
  :deep(.v-stepper-header) {
    display: none;
  }
  :deep(.v-stepper-window) {
    margin: 0;
  }
}
.j-cash-mobile-menu-header {
  background: $j-gradient-btn-main;
}
.j-cash-mobile-menu-list__item {
  background-color: $j-color-bg-block-light;
  grid-template-columns: 40px 1fr auto;
}
</style>
<template>
  <general-tabs
    v-model="activeTabIndex"
    :items="Object.values(CASH_TABS)"
    center
    extended-x-padding
    light-bg-color
    @update:model-value="changeTab"
  />
  <j-cash ref="desktopCash" />
</template>
<script setup lang="ts">
import { useCashStore } from '~/stores/cash';
import {
  CASH_TABS,
  CASH_TYPES,
} from '~/constants/cash';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import type { CashTypes } from '~/types/stores/cash';
import type { CashInstance } from '~/types/general/cash';

const desktopCash = ref<CashInstance>(null);
const cashStore = useCashStore();
const { setCashType } = cashStore;
const activeTabIndex = ref<number>(0);
const route = useRoute();

const changeTab = () => {
  setCashType(CASH_TYPES[activeTabIndex.value] as CashTypes);
  desktopCash.value?.initializeCash();
};

onBeforeMount(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const cashierQuery = route.query[REDIRECTION_ROUTES_QUERIES.cashier]
    || queryParams.get(REDIRECTION_ROUTES_QUERIES.cashier);

  if (CASH_TYPES.includes(cashierQuery as string)) {
    activeTabIndex.value = CASH_TYPES.indexOf(cashierQuery as string);
  }
  setCashType(CASH_TYPES[activeTabIndex.value] as CashTypes);
});
</script>
<template>
  <client-only>
    <Teleport :to="teleportTo">
      <j-cash-promotions-parts-promo-card-small
        :class="[
          'j-cash-promotion-block',
          'ml-auto',
          {
            'j-cash-promotion-block--mobile': isMobile && isCashMainPage,
            'order-first my-2': isCashMainPage,
            'mt-4 mr-auto': isMobile && !isCashMainPage,
          },
        ]"
        :promo-card-data="promoCardData"
        is-activated
        hide-verification-control
      />
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
import type { PromotionItem } from '~/types/pages/cabinet';

const componentProps = defineProps({
  teleportTo: {
    type: String,
    default: '',
  },
  promoCardData: {
    type: Object as PropType<PromotionItem>,
    default: undefined,
  },
});

const { isMobile } = useDevice();
const isCashMainPage = computed(() => componentProps.teleportTo === '#cash'
  || componentProps.teleportTo === '.v-application__wrap');
</script>

<style scoped lang="scss">
.j-cash-promotion-block--mobile {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
<template>
  <nuxt-layout>
    <transition name="fade">
      <j-loader-indicator
        v-if="getShowPageLoader"
        fixed
      />
    </transition>
    <nuxt-page />
  </nuxt-layout>
  <j-notifications v-if="!getShowPageLoader" />
  <general-modal
    v-model="showTermsModal"
    persistent
    width="412"
  >
    <template #header>
      <general-text
        dictionary-key="terms_and_conditions_modal_title"
        :class="termsAndConditionsModalTitleClasses"
      />
    </template>
    <template #body>
      <general-text
        dictionary-key="terms_and_conditions_modal_text"
        class="px-8 mb-10 text-center j-text--fs-12 text-wrap"
      />
      <general-checkbox
        v-model="isAcceptTerms"
        :ripple="false"
        max-width="max-content"
        class="mx-auto px-8 mb-6"
      >
        <general-text
          dictionary-key="terms_and_conditions_modal_agreement"
          class="j-text--white j-text--fs-12"
        />
      </general-checkbox>
    </template>
    <template #footer>
      <v-btn
        :loading="loadingModalConfirmBtn"
        :disabled="!isAcceptTerms"
        :color="scssVariables.jColorPrimary"
        width="175"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="confirmModalHandler"
      >
        {{ $t('general_confirm') }}
      </v-btn>
      <v-btn
        :loading="loadingModalDeclineBtn"
        :disabled="isDeclineTerms"
        :color="scssVariables.jColorBtnRegular"
        width="117"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="declineModalHandler"
      >
        {{ $t('general_decline') }}
      </v-btn>
    </template>
  </general-modal>
  <general-modal
    v-model="getShowCashModal"
    width="1355"
    :height="cashModalHeight"
    :is-cash-mobile="isMobile"
    :persistent="isMobile"
    :bg-color="scssVariables.jColorBgBlockDark"
    hide-footer
    :external-close-btn="isMobile"
    @update:model-value="closeCashModal"
  >
    <template #body>
      <j-cash-mobile-wrap v-if="isMobile" />
      <j-cash-desktop-wrap v-else />
    </template>
  </general-modal>
</template>

<script setup lang='ts'>
import { storeToRefs } from 'pinia';
import {
  useDisplay,
  useLocale,
} from 'vuetify';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import { useLayoutStore } from '~/stores/layout';
import { useCashStore } from '~/stores/cash';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import type { NotificationsItem } from '~/types/components/General/Notifications';

useHead({
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    },
  ],
});
const scssVariables = useScssVariables();
const globalsStore = useGlobalsStore();
const cashStore = useCashStore();
const { closeCashModal } = cashStore;
const { getShowCashModal } = storeToRefs(cashStore);
const route = useRoute();
const isGamePage = route.path.includes('/game/');
const {
  setNotification,
  acceptTerms,
  setNotificationsFromStorage,
  showTermsAndConditions,
} = globalsStore;
const i18n = useI18n();
const authStore = useAuthStore();
const {
  initChat,
  logout,
} = authStore;
const { getUserData } = storeToRefs(authStore);
const { current } = useLocale();
const setLocale = () => {
  i18n.locale.value = getUserData.value.locale;
  current.value = getUserData.value.locale;
};
const { isMobile } = useDevice();
const isMaintenanceModeOn = useRuntimeConfig()?.public?.maintenance;
const {
  mobile,
  sm,
  xs,
} = useDisplay();
const isMobileMode = computed(() =>  mobile.value);
const isSmDisplay = computed(() =>  sm.value);
const isXsDisplay = computed(() =>  xs.value);
const showTermsModal = computed(
  () => getUserData.value.showTermsAndConditions &&
    !getUserData.value.termsAccepted &&
    !route.path.includes('terms-and-conditions'),
);
const termsAndConditionsModalTitleClasses = computed(() => [
  'px-7',
  'mb-4',
  'text-center',
  'j-text--fs-24',
  'j-text--white',
  'text-wrap',
  { 'mx-auto': i18n.locale.value === 'en' },
  { 'terms-and-conditions-modal-text': i18n.locale.value === 'en' },
]);
const cashModalHeight = computed(() => isXsDisplay?.value
  ? '100%'
  : '688px');
const isDeclineTerms = ref(false);
const isAcceptTerms = ref(false);
const loadingModalDeclineBtn = ref(false);
const loadingModalConfirmBtn = ref(false);

provide('isMobileMode', isMobileMode);
provide('isSmDisplay', isSmDisplay);
provide('isXsDisplay', isXsDisplay);
//Preloader - I use data from the store and use hooks to track the end of page rendering
const layoutStore = useLayoutStore();
const { setPageLoader } = layoutStore;
const { getShowPageLoader } = storeToRefs(layoutStore);
const nuxtApp = useNuxtApp();

nuxtApp.hook('page:finish', () => {
  setPageLoader(false);
  useScrollToAnchor(route.fullPath);
});
// Life cycle hooks
onMounted(() => {
  setLocale();
  if (!isMaintenanceModeOn && !isGamePage) {
    initChat(getUserData.value.loggedIn);
  }

  if (getUserData.value.loggedIn) {
    showTermsAndConditions();
  }

  const notificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications);

  if (notificationsFromStorage?.length) {
    setNotificationsFromStorage(notificationsFromStorage);
  }

  const isCookiePolicy = useLocalStorageObject.get(LOCAL_STORAGE.cookiePolicy);
  const hasCookieNotification = notificationsFromStorage?.some(
    (notification: NotificationsItem) => notification.isCookie,
  );

  if (!isCookiePolicy && !hasCookieNotification) {
    setNotification({
      dictionaryKeyText: 'notifications_cookie',
      vertical: false,
      timeout: -1,
      isCookie: true,
      buttons: [
        {
          class: 'class-horizontal',
          color: scssVariables.jColorPrimary,
          dictionaryKey: 'general_agree',
          action: 'readCookie',
        },
      ],
    });
  }
});
const declineModalHandler = async () => {
  try {
    isAcceptTerms.value = false;
    loadingModalDeclineBtn.value = true;
    await logout();
    setPageLoader(false);
  } catch (error) {
    throw(error);
  } finally {
    loadingModalDeclineBtn.value = false;
  }
};
const confirmModalHandler = async () => {
  try {
    isDeclineTerms.value = true;
    loadingModalConfirmBtn.value = true;
    await acceptTerms();
  } catch (error) {
    throw(error);
  } finally {
    isDeclineTerms.value = false;
    loadingModalConfirmBtn.value = false;
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.terms-and-conditions-modal-text {
  max-width: 310px;
}
</style>

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import type { SSR } from '~/types/plugins/vuetify';
import { customSVGs } from '~/helpers/customSVGs';

export default defineNuxtPlugin((nuxtApp) => {
  let ssr: SSR = true;

  // Hack for fixing hydration problem(incorrect isMobileMode vuetify value in onMounted hook)
  if (import.meta.server) {
    if (!nuxtApp.ssrContext?.event?.node?.req?.headers?.['user-agent']?.toLowerCase()?.includes('mobi')) {
      ssr = { clientWidth: 1280 };
    }
  } else {
    if (!navigator.userAgent?.toLowerCase()?.includes('mobi')) {
      ssr = { clientWidth: 1280 };
    }
  }

  const vuetify = createVuetify({
    ssr,
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      sets: { custom: customSVGs },
    },
    theme: { defaultTheme: 'dark' },
  });

  nuxtApp.vueApp.use(vuetify);
});

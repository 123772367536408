<template>
  <client-only>
    <Teleport to="#tab-bonuses">
      <general-text
        dictionary-key="general_choose_bonus"
        class="j-text j-text--fs-24 j-text--white text-center mb-5"
      />
      <div class="j-cash-promotions-block d-flex flex-wrap justify-center justify-sm-start">
        <template
          v-for="promoCardData of promoCardWithDeposit"
          :key="promoCardData.id_bonus"
        >
          <j-cash-promotions-parts-promo-card-small
            v-if="isMobile"
            :promo-card-data="promoCardData"
            :is-activated="promoCardData.state === PROMOTION_DATA.active"
            :loading="promoCardData.id_bonus === chosenPromoId"
            @click="activatePromo(promoCardData)"
          />
          <j-cash-promotions-parts-promo-card
            v-else
            :promo-card-data="promoCardData"
            :is-activated="promoCardData.state === PROMOTION_DATA.active"
            :loading="promoCardData.id_bonus === chosenPromoId"
            @click="activatePromo(promoCardData)"
          />
        </template>
        <j-cash-promotions-parts-promo-card-small
          v-if="isMobile"
          :is-activated="chosenPromoId === null"
          :loading="chosenPromoId === null && loading"
          hide-verification-control
          @click="cancelPromo"
        />
        <j-cash-promotions-parts-promo-card
          v-else
          :is-activated="chosenPromoId === null"
          :loading="chosenPromoId === null && loading"
          hide-verification-control
          @click="cancelPromo"
        />
      </div>
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCabinetStore } from '~/stores/cabinet';
import { useAuthStore } from '~/stores/auth';
import {
  PLATFORM_BY_DEVICE_TYPE,
  PROMOTION_DATA,
} from '~/constants/general';
import type { PromotionItem } from '~/types/pages/cabinet';

const emit = defineEmits([
  'promo-toggle',
]);
const { isMobile } = useDevice();
const chosenPromoId = ref<number | null | undefined>(undefined);
const loading = ref(false);
const cabinetStore = useCabinetStore();
const authStore = useAuthStore();
const { getPromotions } = storeToRefs(cabinetStore);
const {
  fetchPromotionsData,
  participationAction,
} = cabinetStore;
const { fetchUserBonusesCount } = authStore;
const promoCardWithDeposit = computed(() => getPromotions.value.filter((card) => card.deposit_from));
const activePromo = computed(() => promoCardWithDeposit.value.find((promo) => promo.state === PROMOTION_DATA.active));
const activatePromo = async (promoCardData: PromotionItem) => {
  if ((!loading.value && activePromo.value?.id_bonus !== promoCardData.id_bonus)
    && (!promoCardData.mail && !promoCardData.phone)) {
    try {
      loading.value = true;
      chosenPromoId.value = promoCardData.id_bonus;
      await participationAction(PROMOTION_DATA.join, promoCardData.id_bonus);

      await Promise.allSettled([
        fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]),
        fetchUserBonusesCount(),
      ]);
      emit('promo-toggle');
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
      chosenPromoId.value = undefined;
    }
  }
};
const cancelPromo = async () => {
  if (!loading.value) {
    try {
      loading.value = true;
      chosenPromoId.value = null;

      if (activePromo.value) {
        await participationAction(PROMOTION_DATA.cancel, activePromo.value.id_bonus);
        await fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
      }
      emit('promo-toggle');
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.j-cash-promotions-block {
  gap: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 380px);
  margin: 0 -16px 0 0;
  padding-right: 16px;
  @include custom-scrollbar($j-color-primary, $j-color-bg-block-light);
  @media (min-width: 600px) {
    max-height: 310px;
  }
  @media (min-width: 1280px) {
    margin: 0 -24px 0 0;
    padding-right: 24px;
  }
}
</style>
